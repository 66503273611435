<template>
  <section>
    <h2>Kwaliteit</h2>
    <p>Een orthopedagoog probeert ervoor te zorgen dat kinderen/jongeren met
    ontwikkelings-, leer- en/of gedragsproblemen de hulp krijgen die het best
     bij hun karakter, belemmeringen én mogelijkheden aansluit met als doel hun
      ontwikkeling, functioneren en hun welzijn te maximaliseren.</p>

    <p>Ieder kind en elk gezin is anders. Elk kind/jongere verdient daarom een
     maatwerkoplossing.</p>

    <h3>Achtergrond</h3>

    <p>Van 2010-2017 heb ik als groepsleerkracht in het basisonderwijs gewerkt.
     In die tijd heb ik veel ervaring opgedaan met groepssituaties, kinderen
   en hun verzorgers. Mijn kracht ligt bij het zien van mogelijkheden van elk
    kind en het kind vanuit deze basis verder te begeleiden en te stimuleren.
  Als orthopedagoge vind ik belangrijk om mij in te zetten voor een zo goed
mogelijke samenwerking tussen alle betrokkenen bij het kind.</p>

    <p>Ik ben als Basis-orthopedagoog met diagnostiek-aantekening geregistreerd
     bij de NVO (Nederlandse Vereniging voor Orthopedagogen registratienummer
   21100 ) en als behandelaar bij de SKJ (Stichting Kwaliteitsregister Jeugd,
 registratienummer: 120013661). Ik werk conform de NVO beroepscode en hanteer
de NVO algemene voorwaarden. Om mijn kennis up tot date te houden, volg ik
 jaarlijks cursussen, opleidingen in het vakgebied. Daarnaast is er sprake van
  intervisie door mede-orthopedagogen en supervisie door orthopedagoog-generalisten
   of GZ-psychologen.</p>
  </section>

</template>
<style scoped lang="scss">
section {
  text-align:left;
  padding: 10px;
}
</style>
